<template>
  <default-layout>
    <div class="text-center mt-4 px-7">
      <h2 class="leading-7">Xác thực tài khoản tăng cường bảo mật<br /></h2>

      <div class="my-8 ml-4">
        <div class="flex flex-row items-center">
          <div class="leading-none flex items-center justify-center">
            <span class="w-9 text-success">
              <svg
                class="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </span>
          </div>
          <div class="leading-snug ml-4 text-primary-color text-left">
            Xác thực thông tin cơ bản
          </div>
        </div>
        <div class="divider p-2"></div>
        <!--DO NOT DO EKYC-->
        <div v-if="!isDoneEKYC" class=" flex flex-row items-center">
          <div class=" leading-none flex items-center justify-center">
            <span class="w-9 text-red-500">
              <svg
                class="w-full h-full"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </span>
          </div>
          <div class="leading-snug ml-4 text-primary-color text-left">
            Xác thực hình ảnh gương mặt và CMND/ CCCD
          </div>
        </div>
      </div>
      <div>
        <base-button
          class="h-12 w-full border-16 bg-tertiary text-white"
          @click="handleClick"
        >
          Xác thực ngay
        </base-button>
      </div>
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from '@/layouts/DefaultLayout';
import BaseButton from '@/components/Base/BaseButton';
import constRouter from '@/constants/constRouter';
import { mapGetters } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  components: {
    BaseButton,
    DefaultLayout,
  },
  name: 'RegisterProgress',
  data() {
    return {
      //================KHỚP 100%================
      // faceMatch: {
      //   per: 100,
      //   status: 'match',
      // },
      // isDoneEKYC: true,
      //================KHỚP================
      // faceMatch: {
      //   per: 80,
      //   status: 'match',
      // },
      // isDoneEKYC: true,
      //================KHÔNG KHỚP================
      // faceMatch: {
      //   per: 25,
      //   status: 'unmatch',
      // },
      // isDoneEKYC: true,
      //================ĐANG XỬ LÝ================
      // faceMatch: null,
      // isDoneEKYC: true,
      //================BỎ QUA EKYC================
      // faceMatch: null,
      // isDoneEKYC: false,
    };
  },
  setup() {
    const router = useRouter();
    const handleClick = () => {
      router.push({ name: constRouter.EKYC_INTRO.name });
    };

    return {
      handleClick,
    };
  },
  computed: {
    ...mapGetters({
      isDoneEKYC: 'isDoneEKYC',
    }),
  },
};
</script>

<style scoped>
.divider {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: 18px;
}
</style>
